import { lazy, Suspense } from 'react'
//import { Navigate } from 'react-router-dom'
let Home = lazy(() => import('../pages/home'))
let Sqdxdxkc = lazy(() => import('../pages/sqdx/index'))
let Sqdxdxys = lazy(() => import('../pages/sqdx/dxys/index'))
let Sqdxzxgl = lazy(() => import('../pages/sqdx/zxgl/index'))
let Sqdxxzdx = lazy(() => import('../pages/sqdx/xzdx/index'))
let Yjssqyd = lazy(() => import('../pages/yjs/index'))
let Yjssqfx = lazy(() => import('../pages/yjs/sqfx/index'))
let Yjssqgz = lazy(() => import('../pages/yjs/sqgz/index'))
let Bhcc = lazy(() => import('../pages/sqdx/bhcc/index'))
let Fmcc = lazy(() => import('../pages/sqdx/fmcc/index'))
let Nscc = lazy(() => import('../pages/sqdx/nscc/index'))
let Smc = lazy(() => import('../pages/sqdx/smc/index'))
let Pcc = lazy(() => import('../pages/sqdx/pcc/index'))
let Wlac = lazy(() => import('../pages/sqdx/wlac/index'))
let Benkindex = lazy(() => import('../pages/benk/index'))
let Benkwenshu = lazy(() => import('../pages/benk/wenshu/index'))
let Benkfeiyong = lazy(() => import('../pages/benk/feiyong/index'))
let Benkqianzheng = lazy(() => import('../pages/benk/qianzheng/index'))
let Benkxuanzxy = lazy(() => import('../pages/benk/xuanzxy/index'))
let Mxbj = lazy(() => import('../pages/mxbj/index'))
let Contact = lazy(() => import('../pages/contact/index'))
let Consultant = lazy(() => import('../pages/consultant/index'))
let Questions = lazy(() => import('../pages/questions/index'))
let Member = lazy(() => import('../pages/member/index'))
let Not = lazy(() => import('../components/error/Error404'))

let load = (Comp) => {
    return (<Suspense fallback={<h2 className="loading"><span class="load-span"></span><span class="load-span"></span><span class="load-span"></span></h2>}>
        <Comp></Comp>
    </Suspense>)
}//eslint-disable-next-line
export default [
	{
	    path: '/',
	    element: load(Home),
	    meta: {title: '首页',isShow: true}
	},
	{
	    path: '/sqdx/index',
	    element: load(Sqdxdxkc),
	    meta: {title: '社区大学课程',isShow: true}
	},
	{
	    path: '/sqdxindex/:id',
	    element: load(Sqdxdxkc),
	    meta: {title: '社区大学课程',isShow: true}
	},
	{
	    path: '/sqdx/dxys/index',
	    element: load(Sqdxdxys),
	    meta: {title: '社区大学优势',isShow: true}
	},
	{
	    path: '/sqdx/dxysindex/:id',
	    element: load(Sqdxdxys),
	    meta: {title: '社区大学优势',isShow: true}
	},
	{
	    path: '/sqdx/zxgl/index',
	    element: load(Sqdxzxgl),
	    meta: {title: '社区大学转学完全攻略',isShow: true}
	},
	{
	    path: '/sqdx/zxglindex/:id',
	    element: load(Sqdxzxgl),
	    meta: {title: '社区大学转学完全攻略',isShow: true}
	},
	{
	    path: '/sqdx/xzdx/index',
	    element: load(Sqdxxzdx),
	    meta: {title: '选择社区大学',isShow: true}
	},
	{
	    path: '/sqdx/xzdxindex/:id',
	    element: load(Sqdxxzdx),
	    meta: {title: '选择社区大学',isShow: true}
	},
	{
	    path: '/yjs/index',
	    element: load(Yjssqyd),
	    meta: {title: '申请要点',isShow: true}
	},
	{
	    path: '/yjsindex/:id',
	    element: load(Yjssqyd),
	    meta: {title: '申请要点',isShow: true}
	},
	{
	    path: '/yjs/sqfx/index',
	    element: load(Yjssqfx),
	    meta: {title: '申请方向',isShow: true}
	},
	{
	    path: '/yjs/yjssqfx/:id',
	    element: load(Yjssqfx),
	    meta: {title: '申请方向',isShow: true}
	},
	{
	    path: '/yjs/sqgz/index',
	    element: load(Yjssqgz),
	    meta: {title: '申请规划',isShow: true}
	},
	{
	    path: '/yjs/yjssqgz/:id',
	    element: load(Yjssqgz),
	    meta: {title: '申请规划',isShow: true}
	},
	{
	    path: '/sqdx/bhcc/index',
	    element: load(Bhcc),
	    meta: {title: 'BHCC',isShow: true}
	},
	{
	    path: '/sqdx/fmcc/index',
	    element: load(Fmcc),
	    meta: {title: 'FMCC',isShow: true}
	},
	{
	    path: '/sqdx/nscc/index',
	    element: load(Nscc),
	    meta: {title: 'NSCC',isShow: true}
	},
	{
	    path: '/sqdx/smc/index',
	    element: load(Smc),
	    meta: {title: 'SMC',isShow: true}
	},
	{
	    path: '/sqdx/pcc/index',
	    element: load(Pcc),
	    meta: {title: 'PCC',isShow: true}
	},
	{
	    path: '/sqdx/wlac/index',
	    element: load(Wlac),
	    meta: {title: 'WLAC',isShow: true}
	},
	{
	    path: '/benk/index',
	    element: load(Benkindex),
	    meta: {title: '申请条件',isShow: true}
	},
	{
	    path: '/benkindex/:id',
	    element: load(Benkindex),
	    meta: {title: '申请条件',isShow: true}
	},
	{
	    path: '/benk/wenshu/index',
	    element: load(Benkwenshu),
	    meta: {title: '文书指南',isShow: true}
	},
	{
	    path: '/benk/benkwenshu/:id',
	    element: load(Benkwenshu),
	    meta: {title: '文书指南',isShow: true}
	},
	{
	    path: '/benk/feiyong/index',
	    element: load(Benkfeiyong),
	    meta: {title: '费用解读',isShow: true}
	},
	{
	    path: '/benk/benkfeiyong/:id',
	    element: load(Benkfeiyong),
	    meta: {title: '费用解读',isShow: true}
	},
	{
	    path: '/benk/qianzheng/index',
	    element: load(Benkqianzheng),
	    meta: {title: '签证指南',isShow: true}
	},
	{
	    path: '/benk/benkqianzheng/:id',
	    element: load(Benkqianzheng),
	    meta: {title: '签证指南',isShow: true}
	},
	{
	    path: '/benk/xuanzxy/index',
	    element: load(Benkxuanzxy),
	    meta: {title: '选择学校',isShow: true}
	},
	{
	    path: '/benk/benkxuanzxy/:id',
	    element: load(Benkxuanzxy),
	    meta: {title: '选择学校',isShow: true}
	},
	{
	    path: '/mxbj/index',
	    element: load(Mxbj),
	    meta: {title: '名校服务',isShow: true}
	},
	{
	    path: '/contact/index',
	    element: load(Contact),
	    meta: {title: '联系我们',isShow: true}
	},
	{
	    path: '/consultant/index',
	    element: load(Consultant),
	    meta: {title: '嘉佑安顾问',isShow: true}
	},
	{
	    path: '/questions/index',
	    element: load(Questions),
	    meta: {title: '嘉佑安问答',isShow: true}
	},
	{
	    path: '/member/index',
	    element: load(Member),
	    meta: {title: '"嘉油站"会员',isShow: true}
	},
    {
        path: '*',
        element: load(Not),
        meta: {
            title: '404',
            isShow: false
        }
    }
    
]