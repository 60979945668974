import React from 'react';
import { BrowserRouter, Routes, Route, NavLink, Navigate, useRoutes } from 'react-router-dom'
import './App.css';
import './index.css';
import './css/js.css';
import './css/m1.css';
import './css/m-sqdx.css';
import './config';

import routes from './router'

function App() {
    let Routes = useRoutes(routes)
  return (
    <div className="App">
	{Routes}
    </div>
	
  );
}


export default App;
